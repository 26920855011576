exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-data-protection-notice-tsx": () => import("./../../../src/pages/dataProtectionNotice.tsx" /* webpackChunkName: "component---src-pages-data-protection-notice-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/termsOfUse.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-documentation-tsx": () => import("./../../../src/templates/documentation.tsx" /* webpackChunkName: "component---src-templates-documentation-tsx" */),
  "component---src-templates-guide-tsx": () => import("./../../../src/templates/guide.tsx" /* webpackChunkName: "component---src-templates-guide-tsx" */),
  "component---src-templates-overview-tsx": () => import("./../../../src/templates/overview.tsx" /* webpackChunkName: "component---src-templates-overview-tsx" */),
  "component---src-templates-preview-tsx": () => import("./../../../src/templates/preview.tsx" /* webpackChunkName: "component---src-templates-preview-tsx" */)
}

