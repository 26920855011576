require('prismjs/themes/prism-tomorrow.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');

const SCRIPT_ID = 'bosch-frok-script';
const DEMO_SCRIPT_ID = 'bosch-frok-demo-script';

exports.onRouteUpdate = () => {
  /**
   * Remove current frontend-kit.js from DOM
   * Remove current frontend-kit-demo.js from DOM
   */

  const scriptElement = document.getElementById(SCRIPT_ID);
  const demoScriptElement = document.getElementById(DEMO_SCRIPT_ID);
  const scriptElementSrc = scriptElement.src;
  const demoScriptElementSrc = demoScriptElement.src;

  scriptElement.remove();
  demoScriptElement.remove();

  /**
   * Reapply frontend-kit.js to DOM
   */
  const frokScript = document.createElement('script');
  frokScript.id = SCRIPT_ID;
  frokScript.type = 'text/javascript';
  frokScript.src = scriptElementSrc;
  document.body.appendChild(frokScript);

  /**
   * Reapply frontend-kit-demo.js to DOM
   */
  const demoScript = document.createElement('script');
  demoScript.id = DEMO_SCRIPT_ID;
  demoScript.type = 'text/javascript';
  demoScript.src = demoScriptElementSrc;
  document.body.appendChild(demoScript);
};
